<template>
  <div>
    <b-sidebar
      id="structure-people-info-sidebar-right"
      class="structure-sidebar-info-modal"
      right
      bg-variant="white"
      width="407px"
      v-model="isOpen"
      @hidden="closeModal"
    >
      <template #header>
        <div
          :class="[
            'header-sidebar d-flex',
            device === 'mobile' ? 'justify-content-between' : 'justify-content-end'
          ]"
        >
          <span
            v-if="device === 'mobile'"
            class="arrow-left-icon"
            @click="closeModal()"
          >
            <ArrowLeft />
          </span>
          <span
            v-if="device === 'mobile'"
            class="sidebar-title"
          >
            {{ $t(modalTitle) }}
          </span>
          <span
            class="edit-people-icon"
            @click="editPerson(personInfo)"
          >
            <OpenIcon />
          </span>
          <span
            v-if="device !== 'mobile'"
            class="unlink-icon"
            @click="$emit('onUnlink', personInfo)"
          >
            <UnLinkIcon />
          </span>
          <span
            v-if="device !== 'mobile'"
            class="transfer-icon"
            @click="$emit('onTransfer', personInfo)"
          >
            <TransferIcon />
          </span>
          <span
            v-if="device !== 'mobile'"
            class="close-icon"
            @click="closeModal()"
          >
            <CloseIcon />
          </span>
        </div>

        <div class="separator"></div>
      </template>
      <template>
        <div>
          <div class="first-block">
            <div class="picture">
              <img
                v-if="personInfo.photo"
                :src="personInfo.photo"
                alt="User photo"
              />
              <avatar-placeholder
                v-else
                :size="device !== 'mobile' ? '80px' : '50px'"
                :text="picturePlaceholder(personInfo.first_name, personInfo.last_name)"
              />
            </div>
            <div class="person-name">
              <span>{{ personInfo.first_name }} {{ personInfo.last_name }}</span>
            </div>
          </div>
          <div class="separator"></div>
          <div class="second-block">
            <div class="info-title">{{ $t('DepartmentPage.PersonInfo') }}</div>
            <div class="first-line">
              <div class="id-block">
                <div class="label">ID</div>
                <div class="id info">
                  {{ personInfo.guid }}
                </div>
              </div>
              <div class="code-block">
                <div class="label">{{ $t('ResourceStatus.Code') }}</div>
                <div class="code info">
                  {{ personInfo.code }}
                </div>
              </div>
            </div>
            <div class="second-line">
              <div class="email-block">
                <div class="label">E-mail</div>
                <div class="email info">
                  {{ personInfo.email }}
                </div>
              </div>
              <div class="phone-block">
                <div class="label">{{ $t('DepartmentPage.Telephone') }}</div>
                <div class="phone info">
                  {{ personInfo.phone }}
                </div>
              </div>
            </div>
            <div class="third-line">
              <div class="role-block">
                <div class="label">{{ $t('DepartmentPage.Function') }}</div>
                <div class="role">
                  <div
                    v-for="role in personInfo.functions"
                    :key="role.id"
                    class="tags"
                  >
                    {{ $t(role.name) }}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="separator"></div>
          <div class="third-block">
            <div class="title-department">{{ $t('PagesViews.Departments') }}</div>
            <div class="all-departments">
              <div
                v-for="dept in personInfo.departments"
                :key="dept.id"
                class="department"
              >
                {{ $t(dept.name) }}
              </div>
            </div>
          </div>
        </div>
      </template>
    </b-sidebar>
  </div>
</template>

<script>
  import { BSidebar } from 'bootstrap-vue';
  import { mapState, mapMutations } from 'vuex';
  import AvatarPlaceholder from '@/@core/components/avatar-placeholder/index.vue';

  export default {
    components: {
      BSidebar,
      AvatarPlaceholder,
      CloseIcon: () => import('@core/assets/icons/close-icon.svg'),
      OpenIcon: () => import('@core/assets/icons/open-icon.svg'),
      TransferIcon: () => import('@core/assets/icons/transfer-icon.svg'),
      UnLinkIcon: () => import('@core/assets/icons/unlink-icon.svg'),
      ArrowLeft: () => import('@core/assets/icons/arrow-left.svg')
    },
    props: {
      personInfo: {
        type: Object,
        default: {}
      },
      device: {
        type: String,
        default: 'desktop'
      },
      modalTitle: {
        type: String,
        default: ''
      }
    },
    data() {
      return {
        isOpen: false
      };
    },
    mounted() {
      this.UPDATE_MODAL_INFO_TOGGLE(false);
    },
    methods: {
      ...mapMutations('structures', ['UPDATE_MODAL_INFO_TOGGLE']),
      ...mapMutations('people', ['UPDATE_EDIT_PERSON']),
      closeModal() {
        this.UPDATE_MODAL_INFO_TOGGLE(false);
      },
      picturePlaceholder(first_name, last_name) {
        const firstLetter = (str) => String(str)?.[0]?.toUpperCase() ?? '';
        return `${firstLetter(first_name)}${firstLetter(last_name)}`;
      },
      editPerson(person) {
        this.UPDATE_EDIT_PERSON(person);
        this.$router.push({
          name: 'people'
        });
      }
    },
    computed: {
      ...mapState('structures', {
        isModalInfoOpen: 'isModalInfoOpen'
      })
    },
    watch: {
      isModalInfoOpen(v) {
        this.isOpen = v;
      }
    }
  };
</script>
<style lang="scss">
  .structure-sidebar-info-modal {
    .b-sidebar.b-sidebar-right {
      transition: all 0.5s ease-out;
      border-left: 1px solid #cfc4be;
      @media (max-width: 814px) {
        width: 50vw !important;
      }
      @media (max-width: 480px) {
        width: 100vw !important;
      }
    }

    .b-sidebar-header {
      display: contents;

      .header-sidebar {
        gap: 8px;
        padding: 16px;

        .sidebar-title {
          display: flex;
          align-items: center;
          color: #4c4541;
          font-size: 18px;
          font-style: normal;
          font-weight: 600;

          @media (max-width: 480px) {
            font-size: 16px;
          }
        }
      }

      .arrow-left-icon {
        border-radius: 5px;
        width: 28px;
        height: 28px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        svg {
          width: 12px;
          height: 12px;
          path {
            fill: #974900;
          }
        }

        &:hover {
          background-color: #ffede2 !important;
        }

        &:active {
          background-color: #ffdbc4 !important;
        }
      }

      .close-icon,
      .edit-people-icon,
      .transfer-icon,
      .unlink-icon {
        border: 1px solid #974900;
        border-radius: 5px;
        width: 28px;
        height: 28px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;

        svg {
          width: 12px;
          height: 12px;
          path {
            fill: #974900;
          }
        }

        &:hover {
          background-color: #ffede2 !important;
        }

        &:active {
          background-color: #ffdbc4 !important;
        }
      }
    }

    .separator {
      width: 100%;
      height: 1px;
      background: #cfc4be;
    }

    .b-sidebar {
      height: 100% !important;
      .b-sidebar-body {
        padding-bottom: 16px;
        overflow-x: hidden;

        .first-block {
          display: flex;
          align-items: center;
          gap: 8px;
          padding: 16px;

          .picture img {
            width: 80px;
            border-radius: 50%;

            @media (max-width: 480px) {
              width: 50px;
            }
          }

          .person-name {
            color: #4c4541;
            font-size: 22px;
            font-weight: 600;
            line-height: 30px;

            @media (max-width: 480px) {
              font-size: 16px;
              line-height: 26px;
            }
          }
        }

        .second-block {
          padding: 16px;
          .info-title {
            color: #4c4541;
            font-size: 16px;
            font-weight: 600;
            line-height: 26px;

            @media (max-width: 480px) {
              font-size: 14px;
              line-height: 20px;
            }
          }
          .first-line {
            display: flex;
            gap: 16px;
            padding-block: 16px;
            .id-block,
            .code-block {
              overflow: hidden;
              width: 100%;

              .info {
                white-space: nowrap;
                word-break: break-all;
                overflow: hidden;
                text-overflow: ellipsis;
              }
            }
          }
          .second-line {
            display: flex;
            gap: 16px;
            padding-bottom: 16px;
            .email-block {
              width: 100%;
              overflow: hidden;
              .email {
                white-space: nowrap;
                word-break: break-all;
                overflow: hidden;
                text-overflow: ellipsis;
              }
            }
            .phone-block {
              width: 100%;
            }
          }
          .third-line {
            .role-block {
              .role {
                display: flex;
                gap: 8px;
                .tags {
                  width: fit-content;
                  border-radius: 4px;
                  background: #eceff1;
                  padding-inline: 9px;
                  color: #607d8b;
                  text-align: center;
                  font-size: 12px;
                  font-weight: 600;
                  line-height: 16px;
                }
              }
            }
          }

          .label {
            color: #998f8a;
            font-size: 12px;
            font-weight: 400;
            line-height: 16px;
            padding-bottom: 4px;

            @media (max-width: 480px) {
              font-size: 10px;
              line-height: 14px;
            }
          }

          .info {
            color: #4c4541;
            font-size: 14px;
            font-weight: 600;
            line-height: 20px;

            @media (max-width: 480px) {
              font-size: 12px;
              line-height: 16px;
            }
          }
        }

        .third-block {
          padding: 16px;
          .title-department {
            color: #4c4541;
            font-size: 16px;
            font-weight: 600;
            line-height: 26px;

            @media (max-width: 480px) {
              font-size: 14px;
              line-height: 20px;
            }
          }

          .all-departments {
            .department {
              padding: 7.5px 16px;
              color: #4c4541;
              font-size: 14px;
              font-weight: 600;
              line-height: 20px;
              border-bottom: 1px solid #eee;
            }
          }
        }

        &::-webkit-scrollbar {
          width: 7px;
        }

        &::-webkit-scrollbar-track {
          background: #fff;
        }

        &::-webkit-scrollbar-thumb {
          background-color: #cfc4be;
          border-radius: 9px;
        }
      }
    }
  }
</style>
