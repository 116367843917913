<template>
  <div>
    <b-sidebar
      id="structure-sidebar-right"
      :class="['sidebar-add-edit-unit']"
      right
      bg-variant="white"
      width="407px"
      v-model="isOpen"
      @hidden="closeModal"
    >
      <template #header>
        <div class="header-sidebar d-flex justify-content-between">
          <span class="sidebar-title">
            {{ $t(modalTitle) }}
          </span>

          <span
            class="close-icon"
            @click="closeModal()"
          >
            <CloseIcon />
          </span>
        </div>

        <div class="separator"></div>
      </template>
      <template>
        <b-col md="12">
          <b-form-group
            :label="$t('DepartmentPage.Name')"
            label-for="name"
            :invalid-feedback="
              flagNameEqual ? $t('StructurePage.EqualUnitName') : $t('RequiredField')
            "
          >
            <b-form-input
              id="name"
              v-model="nameInput"
              :placeholder="$t('ResourceStatus.Placeholder')"
              :state="states.name"
            />
          </b-form-group>
        </b-col>
        <b-col md="12">
          <b-form-group
            :label="$t('DepartmentPage.Description')"
            label-for="description"
            :invalid-feedback="$t('RequiredField')"
          >
            <b-form-textarea
              id="description"
              v-model="description"
              :placeholder="$t('ResourceStatus.Placeholder')"
              :state="states.description"
              no-resize
            />
          </b-form-group>
        </b-col>
        <div v-if="customFields.length && isOpen">
          <div class="custom-fields-title col-md-12">{{ $t('StructurePage.CustomFields') }}</div>
          <div
            v-for="field in displayImportantFirst"
            :key="field.id"
          >
            <custom-fields
              :field="field"
              :hasError="states.customFieldsForceError"
              @input="
                (value) => {
                  field.value = value ? value : null;
                }
              "
            />
          </div>
        </div>
      </template>

      <template #footer>
        <b-col
          md="12"
          class="footer-sidebar"
        >
          <div class="d-flex">
            <b-button
              class="btn-cancel btn-footer"
              @click="closeModal()"
            >
              {{ $t('RefuseStatus.Cancel') }}
            </b-button>
            <b-button
              class="btn-save btn-footer"
              @click="addUnit()"
            >
              {{ $t('RefuseStatus.Save') }}
            </b-button>
          </div>
        </b-col>
      </template>
    </b-sidebar>
  </div>
</template>

<script>
  import {
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BButton,
    BRow,
    BCol,
    BSidebar
  } from 'bootstrap-vue';
  import { mapState, mapMutations } from 'vuex';
  import CustomFields from '@core/components/customFields/index.vue';
  import ToastificationContent from '@/@core/components/toastification/ToastificationContent.vue';

  export default {
    components: {
      BFormGroup,
      BFormInput,
      BFormTextarea,
      BButton,
      BRow,
      BCol,
      BSidebar,
      CustomFields,
      CloseIcon: () => import('@core/assets/icons/close-icon.svg')
    },
    props: {
      currentId: {
        type: Number,
        default: null
      },
      customFields: {
        type: Array,
        default: []
      },
      modalTitle: {
        type: String,
        default: ''
      }
    },
    data() {
      return {
        isOpen: false,
        nameInput: '',
        description: '',
        flagNameEqual: false,
        prefix: localStorage.getItem('prefix'),
        states: {
          name: null,
          description: null,
          customFieldsForceError: null
        }
      };
    },
    mounted() {
      this.UPDATE_MODAL_ADD_EDIT_TOGGLE(false);
    },
    methods: {
      ...mapMutations('app', ['UPDATE_FLAG_SHOW_OVERLAY']),
      ...mapMutations('structures', ['UPDATE_MODAL_ADD_EDIT_TOGGLE']),
      closeModal() {
        this.UPDATE_MODAL_ADD_EDIT_TOGGLE(false);
      },
      clearFields() {
        this.nameInput = '';
        this.description = '';
        this.customFields.map((e) => (e.value = null));
      },
      showToast(title, icon, text, variant) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: title,
            icon: icon,
            text: text,
            variant
          }
        });
      },
      clearValidations() {
        this.states.name = null;
        this.states.description = null;
        this.states.structure = null;
        this.states.customFieldsForceError = null;
      },
      getInfoEdit(item) {
        const typeOfModal = this.verifyTypeOfModal();
        if (typeOfModal === 'edit') {
          item.map((e) => {
            if (e.id === this.currentId && !e.isWorkcenter) {
              this.nameInput = e.name;
              this.description = e.description;
              this.customFields.map((f) => {
                e.industrial_parameter.map((g) => {
                  f.value = g.value;
                });
              });
            }
            if (e.child.length > 0) {
              this.getInfoEdit(e.child);
            }
          });
        }
      },
      fieldsValidation() {
        this.clearValidations();
        const validName = (this.states.name =
          this.nameInput && this.flagNameEqual === false ? true : false);

        const validDescription = (this.states.description = this.description !== '');

        const requiredFields = this.customFields.filter((e) => e.required);
        const isCustomFieldsValid = requiredFields.map((e) => {
          if (e.required) {
            return e.value != null && e.value != 0;
          }
        });
        const hasInvalidFields = isCustomFieldsValid.includes(false);
        hasInvalidFields
          ? (this.states.customFieldsForceError = true)
          : (this.states.customFieldsForceError = false);

        return !hasInvalidFields && validDescription && validName;
      },
      verifyTypeOfModal() {
        return this.modalTitle.includes('New') || this.modalTitle.includes('Novo') ? 'new' : 'edit';
      },
      async addUnit() {
        this.flagNameEqual = false;
        const isValid = this.fieldsValidation();
        if (!isValid) return;

        this.UPDATE_FLAG_SHOW_OVERLAY(true);

        const parameters = this.customFields.map((e) => {
          return {
            id: e.id,
            value: e.value ? e.value : null
          };
        });

        const payload = {
          name: this.nameInput,
          description: this.description,
          parent_id: null,
          industrial_parameter: parameters
        };

        const typeOfModal = this.verifyTypeOfModal();

        if (typeOfModal === 'new') {
          await this.$http
            .post(`/api/${this.prefix}/myconfig/site`, payload)
            .then((response) => {
              this.showToast(
                this.$t('IntegrationPage.success'),
                'SmileIcon',
                this.$t('StructurePage.UnitCreated'),
                'success'
              );
              this.UPDATE_MODAL_ADD_EDIT_TOGGLE(false);
              this.clearValidations();
              this.$emit('addedItem');
            })
            .catch((error) => {
              error.response.data.errors.map((e) => {
                if (e.code === 3) {
                  this.flagNameEqual = true;
                } else {
                  this.showToast(
                    this.$t('Error'),
                    'FrownIcon',
                    error.response.data.message,
                    'danger'
                  );
                }
              });
              this.fieldsValidation();
            })
            .finally(() => {
              this.UPDATE_FLAG_SHOW_OVERLAY(false);
            });
        } else if (typeOfModal === 'edit') {
          await this.$http
            .put(`/api/${this.prefix}/myconfig/site/${this.currentId}`, payload)
            .then((response) => {
              this.showToast(
                this.$t('IntegrationPage.success'),
                'SmileIcon',
                this.$t('StructurePage.ChangesSaved'),
                'success'
              );
              this.UPDATE_MODAL_ADD_EDIT_TOGGLE(false);
              this.clearValidations();
              this.$emit('addedItem');
            })
            .catch((error) => {
              error.response.data.errors.map((e) => {
                if (e.code === 3) {
                  this.flagNameEqual = true;
                } else {
                  this.showToast(
                    this.$t('Error'),
                    'FrownIcon',
                    error.response.data.message,
                    'danger'
                  );
                }
              });
              this.fieldsValidation();
            })
            .finally(() => {
              this.UPDATE_FLAG_SHOW_OVERLAY(false);
            });
        }
      }
    },
    computed: {
      ...mapState('structures', {
        isModalAddEditOpen: 'isModalAddEditOpen',
        dragList: 'dragList'
      }),
      displayImportantFirst() {
        return this.customFields.sort((a, b) => {
          if (a.important && !b.important) {
            return -1;
          }
          if (!a.important && b.important) {
            return 1;
          }
          return 0;
        });
      }
    },
    watch: {
      isModalAddEditOpen(v) {
        this.isOpen = v;
        if (!v) {
          this.clearFields();
          this.clearValidations();
        } else {
          this.getInfoEdit(this.dragList);
        }
      }
    }
  };
</script>
<style lang="scss">
  .sidebar-add-edit-unit {
    &.--role-openned {
      .b-sidebar.b-sidebar-right {
        right: 407px;
        transition: all 0.5s ease-out;
        @media (max-width: 814px) {
          left: 0;
          width: 50vw !important;
        }
      }
    }
    &.--role-closed {
      .b-sidebar.b-sidebar-right {
        right: 0;
        transition: all 0.5s ease-out;
      }
    }

    .custom-select {
      cursor: pointer;
      padding: 4px 34px 4px 8px;
      background-image: url("data:image/svg+xml;charset=utf8,%3Csvg width='8' height='6' viewBox='0 0 8 6' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M4.17975 3.78813L7.05862 0.855236L7.64775 1.44457L4.00127 5.14393L0.352313 1.44451L0.941458 0.855158L3.823 3.7882L4.00141 3.96981L4.17975 3.78813Z' fill='%23CFC4BE' stroke='%23CFC4BE' stroke-width='0.5'/%3E%3C/svg%3E") !important;
      &[disabled] {
        border-radius: 5px;
        border: 1px solid #7e7570;
        background-color: #eee;
        background-image: url("data:image/svg+xml;charset=utf8,%3Csvg width='8' height='6' viewBox='0 0 8 6' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M4.17975 3.78813L7.05862 0.855236L7.64775 1.44457L4.00127 5.14393L0.352313 1.44451L0.941458 0.855158L3.823 3.7882L4.00141 3.96981L4.17975 3.78813Z' fill='%237E7570' stroke='%237E7570' stroke-width='0.5'/%3E%3C/svg%3E") !important;
        color: #7e7570 !important;
      }
    }

    .b-sidebar-header {
      display: contents;

      .header-sidebar {
        padding: 16px;

        .sidebar-title {
          display: flex;
          align-items: center;
          color: #4c4541;
          font-size: 18px;
          font-style: normal;
          font-weight: 600;
        }
      }

      .close-icon {
        border: 1px solid #974900;
        border-radius: 5px;
        width: 28px;
        height: 28px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;

        svg {
          width: 12px;
          height: 12px;
          path {
            fill: #974900;
          }
        }

        &:hover {
          background-color: #ffede2 !important;
        }

        &:active {
          background-color: #ffdbc4 !important;
        }
      }

      .separator {
        width: 100%;
        height: 1px;
        background: #cfc4be;
      }
    }

    .b-sidebar {
      height: 100% !important;

      .b-sidebar-body {
        padding: 16px 0;

        .form-group {
          .form-control.is-invalid,
          .form-control.is-valid {
            background-image: none !important;
          }

          .form-control {
            &::placeholder {
              color: #cfc4be;
            }
            &:focus {
              border-color: #974900;
            }
          }
        }

        .custom-fields-title {
          padding-block: 8px;
          color: #4c4541;
          font-size: 16px;
          font-weight: 600;
          line-height: 26px;
        }

        &::-webkit-scrollbar {
          display: none !important;
        }
      }
    }
    .footer-sidebar {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      padding: 16px;

      .btn-footer {
        box-shadow: none;
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
        border-radius: 5px;

        &:hover {
          box-shadow: none;
        }

        &.btn-reset {
          background-color: transparent !important;
          color: #974900 !important;
          font-size: 12px !important;
          border: 1px solid transparent !important;
          padding: 4px 14px;

          &:hover {
            background-color: #ffede2 !important;
          }

          &:active {
            background-color: #ffdbc4 !important;
          }
        }

        &.btn-cancel {
          background-color: transparent !important;
          color: #974900 !important;
          border: 1px solid #974900 !important;
          margin-right: 8px;
          padding: 8px 21px;

          &:hover {
            background-color: #ffede2 !important;
          }

          &:active {
            background-color: #ffdbc4 !important;
          }
        }

        &.btn-save {
          border: 1px solid #974900 !important;
          background-color: #974900 !important;
          color: #ffff !important;
          padding: 8px 21px;

          &:hover {
            background-color: #9f5714 !important;
          }

          &:active {
            background-color: #a45f1f !important;
          }
        }
      }
    }
  }
</style>
