<template>
  <div>
    <b-sidebar
      id="structure-resource-info-sidebar-right"
      class="structure-sidebar-resource-info-modal"
      right
      bg-variant="white"
      width="407px"
      v-model="isOpen"
      @hidden="closeModal"
    >
      <template #header>
        <div
          :class="[
            'header-sidebar d-flex',
            device === 'mobile' ? 'justify-content-between' : 'justify-content-end'
          ]"
        >
          <span
            v-if="device === 'mobile'"
            class="arrow-left-icon"
            @click="closeModal()"
          >
            <ArrowLeft />
          </span>
          <span
            v-if="device === 'mobile'"
            class="sidebar-title"
          >
            {{ $t(modalTitle) }}
          </span>
          <span
            class="edit-resource-icon"
            @click="editResoucer(resourceInfo.id)"
          >
            <OpenIcon />
          </span>
          <span
            v-if="device !== 'mobile'"
            class="unlink-icon"
            @click="$emit('onUnlink', resourceInfo)"
          >
            <UnLinkIcon />
          </span>
          <span
            v-if="device !== 'mobile'"
            class="transfer-icon"
            @click="$emit('onTransfer', resourceInfo)"
          >
            <TransferIcon />
          </span>
          <span
            v-if="device !== 'mobile'"
            class="close-icon"
            @click="closeModal()"
          >
            <CloseIcon />
          </span>
        </div>

        <div class="separator"></div>
      </template>
      <template>
        <div>
          <div class="info-block">
            <div class="info-title">{{ $t('StructurePage.ResourceDetails') }}</div>
            <div class="first-line">
              <div class="code-block">
                <div class="label">{{ $t('ResourceStatus.Code') }}</div>
                <div class="code info">
                  {{ resourceInfo.code }}
                </div>
              </div>
              <div class="name-block">
                <div class="label">{{ $t('ResourceStatus.Name') }}</div>
                <div class="name info">
                  {{ resourceInfo.name }}
                </div>
              </div>
            </div>
            <div class="second-line">
              <div class="type-block">
                <div class="label">{{ $t('StructurePage.Type') }}</div>
                <div class="type info">
                  {{ resourceInfo.type }}
                </div>
              </div>
              <div class="production-block">
                <div class="label">{{ $t('ResourceStatus.ProductionRate') }}</div>
                <div class="production info">
                  {{ resourceInfo.production_rate }}
                </div>
              </div>
            </div>
            <div
              v-if="resourceInfo.industrial_parameter"
              class="third-line d-flex justify-content-between"
            >
              <div
                v-for="field in resourceInfo.industrial_parameter"
                class="custom-fields-block"
                :key="field.label"
              >
                <div class="label">{{ field.label }}</div>
                <div class="custom-fields info">
                  {{ field.name }}
                </div>
              </div>
            </div>
          </div>
          <div class="separator"></div>
        </div>
      </template>
    </b-sidebar>
  </div>
</template>

<script>
  import { BSidebar } from 'bootstrap-vue';
  import { mapState, mapMutations } from 'vuex';
  import router from '@/router';

  export default {
    components: {
      BSidebar,
      CloseIcon: () => import('@core/assets/icons/close-icon.svg'),
      OpenIcon: () => import('@core/assets/icons/open-icon.svg'),
      TransferIcon: () => import('@core/assets/icons/transfer-icon.svg'),
      UnLinkIcon: () => import('@core/assets/icons/unlink-modal-icon.svg'),
      ArrowLeft: () => import('@core/assets/icons/arrow-left.svg')
    },
    props: {
      resourceInfo: {
        type: Object,
        default: {}
      },
      device: {
        type: String,
        default: 'desktop'
      },
      modalTitle: {
        type: String,
        default: ''
      }
    },
    data() {
      return {
        isOpen: false
      };
    },
    mounted() {
      this.UPDATE_MODAL_RESOURCE_INFO_TOGGLE(false);
    },
    methods: {
      ...mapMutations('structures', ['UPDATE_MODAL_RESOURCE_INFO_TOGGLE']),
      ...mapMutations('resource', ['SET_EDIT_ID']),
      closeModal() {
        this.UPDATE_MODAL_RESOURCE_INFO_TOGGLE(false);
      },
      editResoucer(id) {
        this.SET_EDIT_ID(id);
        this.$router.push({ name: 'resources' });
      }
    },
    computed: {
      ...mapState('structures', {
        isModalResourceInfoOpen: 'isModalResourceInfoOpen'
      })
    },
    watch: {
      isModalResourceInfoOpen(v) {
        this.isOpen = v;
      }
    }
  };
</script>
<style lang="scss">
  .structure-sidebar-resource-info-modal {
    .b-sidebar.b-sidebar-right {
      transition: all 0.5s ease-out;
      border-left: 1px solid #cfc4be;
      @media (max-width: 814px) {
        width: 50vw !important;
      }
      @media (max-width: 480px) {
        width: 100vw !important;
      }
    }

    .b-sidebar-header {
      display: contents;

      .header-sidebar {
        gap: 8px;
        padding: 16px;

        .sidebar-title {
          display: flex;
          align-items: center;
          color: #4c4541;
          font-size: 18px;
          font-style: normal;
          font-weight: 600;

          @media (max-width: 480px) {
            font-size: 16px;
          }
        }
      }

      .arrow-left-icon {
        border-radius: 5px;
        width: 28px;
        height: 28px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        svg {
          width: 12px;
          height: 12px;
          path {
            fill: #974900;
          }
        }

        &:hover {
          background-color: #ffede2 !important;
        }

        &:active {
          background-color: #ffdbc4 !important;
        }
      }

      .close-icon,
      .edit-resource-icon,
      .transfer-icon,
      .unlink-icon {
        border: 1px solid #974900;
        border-radius: 5px;
        width: 28px;
        height: 28px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;

        svg {
          width: 12px;
          height: 12px;
          path {
            fill: #974900;
          }
        }

        &:hover {
          background-color: #ffede2 !important;
        }

        &:active {
          background-color: #ffdbc4 !important;
        }
      }
    }

    .separator {
      width: 100%;
      height: 1px;
      background: #cfc4be;
    }

    .b-sidebar {
      height: 100% !important;
      .b-sidebar-body {
        padding-bottom: 16px;
        overflow-x: hidden;

        .info-block {
          padding: 16px;
          .info-title {
            color: #4c4541;
            font-size: 16px;
            font-weight: 600;
            line-height: 26px;

            @media (max-width: 480px) {
              font-size: 14px;
              line-height: 20px;
            }
          }
          .first-line {
            display: flex;
            gap: 16px;
            padding-block: 16px;
            .code-block,
            .name-block {
              overflow: hidden;
              width: 100%;

              .info {
                white-space: nowrap;
                word-break: break-all;
                overflow: hidden;
                text-overflow: ellipsis;
              }
            }
          }
          .second-line {
            display: flex;
            gap: 16px;
            padding-bottom: 16px;
            .type-block {
              width: 100%;
              overflow: hidden;
              .type {
                white-space: nowrap;
                word-break: break-all;
                overflow: hidden;
                text-overflow: ellipsis;
              }
            }
            .production-block {
              width: 100%;
            }
          }
          .third-line {
            .custom-fields-block {
              width: 100%;
              overflow: hidden;
              .custom-fields {
                white-space: nowrap;
                word-break: break-all;
                overflow: hidden;
                text-overflow: ellipsis;
              }
            }
          }

          .label {
            color: #998f8a;
            font-size: 12px;
            font-weight: 400;
            line-height: 16px;
            padding-bottom: 4px;

            @media (max-width: 480px) {
              font-size: 10px;
              line-height: 14px;
            }
          }

          .info {
            color: #4c4541;
            font-size: 14px;
            font-weight: 600;
            line-height: 20px;

            @media (max-width: 480px) {
              font-size: 12px;
              line-height: 16px;
            }
          }
        }

        &::-webkit-scrollbar {
          width: 7px;
        }

        &::-webkit-scrollbar-track {
          background: #fff;
        }

        &::-webkit-scrollbar-thumb {
          background-color: #cfc4be;
          border-radius: 9px;
        }
      }
    }
  }
</style>
